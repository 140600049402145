import React, { useState } from 'react';
import Title from '../../components/common/Title';
import styled from 'styled-components';
import ButtonDanger from '../../components/common/buttons/ButtonDanger';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem 0 2rem;
  margin: auto;
  max-width: 80rem;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 65% 1fr;
  column-gap: 1%;
  margin-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const TextContainer = styled.div``;
const MenuContainer = styled.div`
  position: fixed;
  top: 300px;
  right: 15%; /* Adjust as needed */

  @media screen and (max-width: 1880px) {
    /* Adjust position for smaller viewport widths */
    right: 10%;
  }

  @media screen and (max-width: 1200px) {
    /* Further adjust position for even smaller viewport widths */
    right: 5%;
  }

  @media screen and (max-width: 900px) {
    /* Further adjust position for even smaller viewport widths */
    position: static;
  }
`;

const TextHeader = styled.h2`
  font-size: clamp(1.8rem, 6vw, 2.3rem);
`;
const Underline = styled.div`
  border-bottom: solid 1px;
  --tw-border-opacity: 1;

  border-color: rgb(200 30 30 / var(--tw-border-opacity));
  width: 100%;
  margin-bottom: 0.5rem;
`;

const TextContent = styled.p`
  font-size: 1.3rem;
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
`;

const BoldContent = styled.b``;

const ButtonContainer = styled.div`
  max-width: 280px;
`;

interface TreatmentMap {
  [key: string]: JSX.Element;
}

const treatments: TreatmentMap = {
  fysioterapi: (
    <TextContainer>
      <TextHeader>Fysioterapi</TextHeader>
      <Underline />
      <TextContent>
        {
          'Vi har en filosofi om at behandling skal være aktiv. Dette betyr at trening og aktivitet er viktige momenter for våre fysioterapeuter. Vårt mål er at du som pasient skal være delaktig i din egen rehabilitering og bli i stand til å gjøre noe med dine plager selv.'
        }
        {'\n\n'}
        <BoldContent>{'HVA ER FYSIOTERAPI?\n'}</BoldContent>
        {
          'Fysioterapi er en klinisk og forebyggende virksomhet som utøves av fagpersoner med offentlig autorisasjon. Denne autorisasjonen får terapeuten gjennom en 3-årig høgskoleutdannelse med 1 år i turnustjeneste i etterkant.'
        }
        {'\n\n'}
        {
          'En fysioterapeut arbeider hovedsakelig med plager i muskel- og skjelettsystemet. Hos Helsehuset Vea legger vi vekt på en aktiv tilnærmelse og prøver i samarbeid med pasienten å finne en behandlingsmetode som han/hun selv kan ta del i og som gir en langvarig og forebyggende effekt. Vi har en helhetlig tilnærmelse og ser at ulike faktorer påvirker helsetilstanden til hver enkelt pasient. I tillegg til sykdommer og skader, kan andre faktorer som for eksempel søvn, stress, bekymringer, aktivitetsnivå og kosthold spille inn.'
        }
        {'\n\n'}
        <BoldContent>{'HVORDAN ARBEIDER EN FYSIOTERAPEUT?\n'}</BoldContent>
        {
          'En førstegangskonsultasjon hos en av våre fysioterapeuter består av en grundig kartlegging av helseplagen(e) og gjennomgang av sykehistorien til pasienten. Deretter følger en undersøkelse og funksjonsvurdering gjennom observasjon av bevegelsesmønster og spesifikke tester. Tilsammen danner dette grunnlaget for valg av behandling og individuelle målsettinger for pasienten.'
        }
        {'\n\n'}
        <BoldContent>{'HVA BEHANDLER EN FYSIOTERAPEUT?\n'}</BoldContent>
        {
          'Våre fysioterapeuter kan behandle sykdom, skader og plager i hele muskel- og skjelettsystemet, både akutte og langvarige tilstander. Ulike behandlingsformer kan tas i bruk, for eksempel mobiliserings- og tøyningsteknikker, øvelser og treningsterapi, triggerpunktbehandling (for eksempel ART og dry needling), teknikker for stressmestring gjennom avspenning og andre ulike tiltak.'
        }
      </TextContent>
    </TextContainer>
  ),
  trykkbolge: (
    <TextContainer>
      <TextHeader>Trykkbølgebehandling</TextHeader>
      <Underline />
      <TextContent>
        {'\n'}
        <BoldContent>{'HVA ER TRYKKBØLGEBEHANDLING?\n'}</BoldContent>
        {
          'Trykkbølgebehandling er å påføre et skadet område (muskel/sene) en stor mengde trykkbølger med høyt trykk. Trykkbølgene dannes ved at en luftkompressor danner mekaniske trykkbølger gjennom en applikator (pistol). '
        }
        {'\n\n'}
        {
          'Teorien er at trykkbølgene trigger en respons i det aktuelle vevet man ønsker å behandle. Helt eksakt hva som er den primære virkningsmekanismen til sjokkbølge/trykkbølge er ikke fullt ut klarlagt i forskningen, men en hypotese er at behandlingen påvirker og bryter ned arrvevet som oppstår ved endel langvarige kroniske seneskader. Det påvirker celleveggen til å trigge en reparasjonsprosess. Når bølgene påvirker skadet vev og eventuelt kalk, starter kroppen selv en tilhelingsprosess.'
        }
        {'\n\n'}
        <BoldContent>
          {'HVILKE TILSTANDER BRUKER MAN TRYKKBØLGE PÅ?\n'}
        </BoldContent>
        {
          'Trykkbølgebehandling blir hovedsaklig brukt på langvarige smertetilstander i sener og muskler. Diagnoser hvor trykkbølgebehandling har vist seg å kunne ha god effekt er ved senebetennelser (tendinopati) i skulder, albu (tennisalbu, musearm), hofte (trochanteritt), kne (runners knee, jumpers knee), akilles, hælspore og plantar fascitt.'
        }
        {'\n\n'}
        <BoldContent>{'BEHANDLINGSFORLØP\n'}</BoldContent>
        {
          'Fysioterapeuten vil først gjennomføre en grundig anamnese og undersøkelse for å avdekke om trykkbølgebehandling er et aktuelt tiltak. Trykkbølgebehandling tar vanligvis ca 10-15 min, hvor intensitet og varighet (antall trykkbølger) vil tilpasses den individuelle pasient, avhengig av skadeområdet, senitivitet etc. Det er anbefalt å gjennomføre 3-5 behandling med minimum en uke i mellom hver behandling.'
        }
        {'\n\n'}
        {
          'I etterkant av trykkbølgebehandling er det viktig å jobbe med å både stimulere tilhelning og forebygge at plagene kommer tilbake. Det er derfor anbefalt at man trener/er fysisk aktiv, ved f.eks at man gjør øvelser som fysioterapeuten anbefaler.'
        }
      </TextContent>
    </TextContainer>
  ),
  saletilpasning: (
    <TextContainer>
      <TextHeader>Såletilpasning</TextHeader>
      <Underline />
      <TextContent>
        {'\n'}
        {'GaitTec by AlfaCare'}
        {'\n\n'}
        {
          'Smerte er kroppens måte å fortelle deg at noe ikke stemmer og at den trenger oppmerksomhet for å unngå ytterligere skade. GaitTec-såler fra AlfaCare kan hjelpe pasienter med feilbelastning av foten. Kne- eller hofteplager kan ofte skyldes feilbelastning av foten. Typiske fotrelaterte plager som kan bli redusert med GaitTec såler: skinnebenplager, hælplager, kne- og hofteplager, smerter relatert til akilles og fotfot.'
        }
        {'\n\n'}
        {
          'GaitTec er innleggsåler av høy kvalitet. Sålene er laget av et komfortabelt og formbart materiale. De er utbedret på bakgrunn av flere års forskning i hvordan man reduserer smerte, behandler skader, forebygger og forbedrer kroppens komfort. Sålene stabiliserer foten og underekstremitetene, slik at andre komponenter i behandlingen din blir mer effektive.'
        }
        {'\n\n'}
        {'Når du bestiller såletilpasning, blir det satt av en hel klokketime. Vi starter med å prate om plagene du har. Før vi går gjennom et standardisert testbatteri av øvelser og tester. Dette gir terapeuten et godt innblikk i hvordan du belaster foten din i stående og gående. Terapeuten danner seg med dette et bilde på hvilke ekstra tilpasninger vi evt. må gjøre med sålene for at de skal være optimale for deg. Videre finner vi riktig type såle og varmer den opp oppi din egen sko. Når sålen er oppvarmet starter tilpasningen. Du skal først gå noen minutter med sålen, slik at den former seg etter foten din. Deretter gjennomfører vi noen av testene fra testbatteriet igjen, mens du står på sålen, for å vurdere om det er behov for å bygge sålen ytterligere opp med kiler eller pelotter. Sålene er nå klare for bruk. ' +
          'Vi anbefaler en gradvis tilvenning i starten, da kroppen skal venne seg til et nytt belastningsmønster. Sålene er ikke festet til skoen vi tilpasser de i, og kan byttes mellom ulike skopar. Vi setter opp en oppfølgingstime noen uker etter tilpasningen slik at vi kan gjøre eventuelle justeringer, dersom sålene ikke føles helt optimale.'}
        {'\n\n'}
        {'Et par såler: 850,-\n' +
          'Timepris for tilpasning: 650,-\n' +
          'Samlet: 1500,-'}
      </TextContent>
      <ButtonContainer>
        <ButtonDanger
          fontSize={1}
          buttonText='TIME FOR SÅLE OG TILPASNING'
          buttonLink='https://timebestilling.aspit.no/#/p3270/services/1/appointment/3/calendar#calendar-title'
        />
      </ButtonContainer>
    </TextContainer>
  ),
};

const Treatment = () => {
  const [activeTreatment, setActiveTreatment] = useState<JSX.Element>(
    treatments['fysioterapi'],
  );
  const [activeMenu, setActiveMenu] = useState<string>('fysioterapi');

  const handleMenuChange = (treatment: string) => {
    setActiveMenu(treatment);
    setActiveTreatment(treatments[treatment]);
  };

  const menu = [
    {
      name: 'Fysioterapi',
      treatment: 'fysioterapi',
    },
    {
      name: 'Trykkbølgebehandling',
      treatment: 'trykkbolge',
    },
    {
      name: 'Såletilpasning',
      treatment: 'saletilpasning',
    },
  ];

  return (
    <Container>
      <Title text='Behandling'></Title>
      <ContentContainer>
        {activeTreatment}

        <MenuContainer>
          <div className=' mt-3 max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5'>
            <div className='text-center p-2 h-12 bg-red-600 text-xl text-white'>
              Behandlinger
            </div>
            <div className='px-6 py-4'>
              {menu.map(item => (
                <div>
                  <button
                    key={item.name}
                    className={
                      'underline-offset-8 hover:underline hover:decoration-red-500 text-[18px] leading-6 text-gray-900 ' +
                      (activeMenu === item.treatment
                        ? 'underline decoration-red-500'
                        : '')
                    }
                    onClick={e => {
                      e.preventDefault();
                      handleMenuChange(item.treatment);
                    }}
                  >
                    <div className='group relative flex items-center gap-x-6 rounded-lg px-4 py-2  leading-6 hover:bg-gray-50'>
                      <div className='flex-auto'>{item.name}</div>
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </MenuContainer>
      </ContentContainer>
    </Container>
  );
};

export default Treatment;

import React from 'react';
import Header from './components/common/ui/Header';
import { Route, Routes } from 'react-router-dom';
import Frontpage from './pages/frontpage/Frontpage';
import Employees from './pages/employees/Employees';
import Treatment from './pages/treatment/Treatment';
import Appointment from './pages/appointment/Appointment';
import Footer from './components/common/ui/Footer';
import Contact from './pages/contact/Contact';
import ScrollToTop from './components/ScrollToTop';
import Member from './pages/member/Member';
import Gym from './pages/gym/Gym';
import Clinic from './pages/clinic/Clinic';
import Coffeespace from './pages/coffeespace/Coffeespace';

function App() {
  return (
    <>
      <ScrollToTop>
        <Header />
        <div className='bg-slate-100 min-h-[75vh] mt-[100px] font-poppins'>
          <Routes>
            <Route path='/' element={<Frontpage />}></Route>
            <Route path='/ansatte' element={<Employees />}></Route>
            <Route path='/behandling' element={<Treatment />}></Route>
            <Route path='/bestill-time' element={<Appointment />}></Route>
            <Route path='/kontakt-oss' element={<Contact />}></Route>
            <Route path='/gym' element={<Gym />}></Route>
            <Route path='/bli-medlem' element={<Member />}></Route>
            <Route path='/klinikken' element={<Clinic />}></Route>
            <Route path='/kaffekroken' element={<Coffeespace />}></Route>
          </Routes>
        </div>
        <Footer />
      </ScrollToTop>
    </>
  );
}

export default App;

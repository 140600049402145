import React from 'react';
import styled from 'styled-components';
import Subtitle from '../Subtitle';

const Container = styled.div`
  width: 100%;
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;

  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(200 30 30 / var(--tw-border-opacity));
  border-top-width: 1px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem;
  max-width: 80rem;
  margin: auto;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const ContactContainer = styled.div``;

const OpenContainer = styled.div``;

const SocialContainer = styled.div``;

const TextContent = styled.p`
  font-size: 1rem;
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
`;

const Underline = styled.div`
  border-bottom: solid 1px;
  border-color: rgb(200 30 30 / var(--tw-border-opacity));
  width: 100%;
  margin-bottom: 1.5rem;
`;

const Link = styled.a`
  color: blue;

  &:hover {
    text-decoration-line: underline;
    text-decoration-color: rgb(224 36 36 / var(--tw-bg-opacity));
  }
`;

const Footer = () => {
  return (
    <Container className='font-poppins'>
      <Wrapper>
        <ContentContainer>
          <ContactContainer>
            <Subtitle text='Kontakt oss'></Subtitle>
            <Underline />
            <TextContent>
              {'Adresse: Bleikmyrvegen 42,\n4276 Veavågen'}
            </TextContent>
            <TextContent>
              {'Telefon: '}
              <Link href='tel:+4746632222'>466 32 222</Link>
              {'\nEpost: '}
              <Link>
                post@helsehusetvea.no
              </Link>
            </TextContent>
          </ContactContainer>
          <OpenContainer>
            <Subtitle text='Åpningstider'></Subtitle>
            <Underline />
            <TextContent>
              {
                'Senter: Mandag - Fredag: 08:00 - 17:00\nLørdag - Søndag: Stengt'
              }
            </TextContent>
            <TextContent>
              {
                'Telefon: Mandag - Fredag: 08:00 - 17:00\nLørdag - Søndag: Stengt'
              }
            </TextContent>
          </OpenContainer>
          <SocialContainer>
            <Subtitle text='Facebook'></Subtitle>
            <Underline />

            <iframe
              name='f6b158f6512c4746d'
              width='100%'
              data-testid='fb:page Facebook Social Plugin'
              title='fb:page Facebook Social Plugin'
              allow='encrypted-media'
              src='https://www.facebook.com/v3.1/plugins/page.php?app_id=243704366102559&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df19c5c592b20645d2%26domain%3Dhelsehusetvea.no%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fhelsehusetvea.no%252Ffc6b17fbf0069b4e7%26relation%3Dparent.parent&amp;container_width=604&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fpg%2FHelsehuset-Vea-460037717762371%2Fabout%2F%3Fref%3Dpage_internal&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=false&amp;width=380'
            ></iframe>
          </SocialContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Footer;

import React from 'react';
import styled from 'styled-components';
import Title from '../../components/common/Title';

import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem 0 2rem;
  margin: auto;
  max-width: 80rem;
`;

const MapsContainer = styled.div`
  height: 600px;
  min-height: 75vh;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
  margin-top: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const LeftContainer = styled.div``;

const RightContainer = styled.div``;

const TextContent = styled.p`
  font-size: 1.3rem;
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
`;

const Link = styled.a`
  &:hover {
    text-decoration-line: underline;
    text-decoration-color: rgb(224 36 36 / var(--tw-bg-opacity));
  }
`;

const Contact = () => {
  const position = { lat: 59.279414, lng: 5.2464348 };

  return (
    <>
      <Container>
        <Title text='Kontakt oss' />
        <ContentContainer>
          <LeftContainer>
            <TextContent>
              {'Adresse: Bleikmyrvegen 42, 4276 Veavågen'}
            </TextContent>
            <TextContent>{'Mandag - Fredag: 08:00 - 17:00'}</TextContent>
          </LeftContainer>
          <RightContainer>
            <TextContent>
              {'Telefon: '}
              <Link href='tel:+4746632222'>466 32 222</Link>
            </TextContent>
            <TextContent>
              {'Epost: '}
              <Link>
                post@helsehusetvea.no
              </Link>
            </TextContent>
          </RightContainer>
        </ContentContainer>
      </Container>
      <MapsContainer>
        <APIProvider apiKey={'AIzaSyAYk-p1eCDT4bdzzV-eOXNNqIgRX52qBVo'}>
          <Map center={position} zoom={17}>
            <Marker position={position} />
          </Map>
        </APIProvider>
      </MapsContainer>
    </>
  );
};

export default Contact;

import React from 'react';
import HeroSection from '../../components/HeroSection';
import gym from '../../assets/gym7.jpg';
import styled from 'styled-components';
import ButtonDanger from '../../components/common/buttons/ButtonDanger';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem 0 2rem;
  margin: auto;
  max-width: 80rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1%;
  padding-bottom: 2rem;
`;

const TextContainer = styled.div``;

const TextContent = styled.p`
  font-size: 1.3rem;
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
`;

const Gym = () => {
  return (
    <div className='pb-16'>
      <HeroSection imageUrl={gym}></HeroSection>
      <Container>
        <ContentContainer>
          <TextContainer>
            <TextContent>
              {
                'Helsehuset Gym er et treningstilbud for kundene våre. I tillegg til at våre fysioterapeuter bruker treningsavdelingen til enkeltbehandling og gruppetimer, så kan pasienter og generelt personer over 40 år være medlemmer på treningsavdelingen. For våre medlemmer har vi inngang med nøkkelkortløsning kl. 06.00-24.00 7 dager i uken året rundt.'
              }
              {'\n\n'}
              {
                'Lokalene til Helsehuset Vea Gym er nyrenovert med toppmoderne utstyr tilpasset for kundene våre. I tillegg til treningsutstyr for styrke, kondisjon og funksjonell trening så kan vi trekke frem et nytt ventilasjonsanlegg som legger til rette for gode treningsforhold. Vi har også inngang og toaletter i treningsavdelingen tilrettelagt for rullestolbrukere.'
              }
              {'\n\n'}
              {
                'Det er viktig for oss at kundene våre føler seg trygg når de trener. Alle som vil begynne å trene hos oss har mulighet for å få oppfølging hos en erfaren fysioterapeut, hvor man får et individuelt tilrettelagt treningsprogram og instruksjon av øvelser. Hvis man i ettertid er usikker og trenger ytterligere instruksjon, så er en av våre fysioterapeuter som oftest tilstede i treningslokalene i åpningstiden (08.00-17.00).'
              }
            </TextContent>
          </TextContainer>
          <ButtonDanger
            buttonText='BLI MEDLEM'
            buttonLink='/bli-medlem'
          ></ButtonDanger>
        </ContentContainer>
      </Container>
    </div>
  );
};

export default Gym;

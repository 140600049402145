import React, { useEffect } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Employee } from '../pages/employees/Employees';

type EmployeeCardProps = {
  employee: Employee;
};

const EmployeeCard = ({ employee }: EmployeeCardProps) => {
  useEffect(() => {}, []);

  return (
    <div className='w-full lg:w-[50%] pt-6 pr-3'>
      <div
        key={employee.name}
        className='w-full'
      >
        <div className='bg-white md:p-4 p-2 shadow rounded'>
          <div className='flex items-center'>
            {employee.imageUrl ? (
              <img
                src={employee.imageUrl}
                alt={'Bilde av ' + employee.name}
                className='w-36 h-36 rounded-full'
              />
            ) : (
              <UserCircleIcon
                className='h-36 w-36 text-gray-300'
                aria-hidden='true'
              />
            )}
            <div className='flex items-start w-full pl-6'>
              <div>
                <p className='text-3xl font-medium leading-8'>
                  {employee.name}
                </p>
                <p className='text-xl leading-normal pt-1 text-gray-600'>
                  {employee.jobTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;

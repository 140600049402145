import React from 'react';
import styled from 'styled-components';

const NewsContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
`;

const HeroHeader = styled.h3`
  font-size: clamp(1.5rem, 6vw, 3rem);
  line-height: 3.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const HeroParagrapgh = styled.h2`
  font-size: clamp(1rem, 5vw, 2rem);
  line-height: 2.5rem;
  max-width: 70%;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const News = () => {
  return (
    <NewsContainer>
      <HeroHeader>VI HAR BYTTET LOKALER!</HeroHeader>
      <HeroParagrapgh>
        {
          'Fra 22.04.24 finner du oss i nye lokaler på Bleikmyrvegen 42, 4276 Veavågen.'
        }
      </HeroParagrapgh>
      <HeroParagrapgh>{'NYE BILDER KOMMER SNART!'}</HeroParagrapgh>
    </NewsContainer>
  );
};

export default News;

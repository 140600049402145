import styled from 'styled-components';
import ButtonDanger from './common/buttons/ButtonDanger';

const HeroContainer = styled.div`
  object-fit: contain;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 650px;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 650px;
    background: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 768px) {
    height: 500px;

    &::before {
      height: 500px;
    }
  }
`;

const HeroTextContainer = styled.div`
  position: absolute;
  top: 20%;
  left: 15%;
  color: #fff;
  max-width: 80%;
  margin-left: 30px;
  font-family: 'Poppins', ui-sans-serif, system-ui, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    @media screen and (max-width: 768px) {
      top: 15%;
      left: 3%
    }  
`;

const HeroHeader = styled.h1`
  font-size: clamp(3rem, 10vw, 4.5rem);
  line-height: 3.5rem;
  margin-bottom: 1rem;
`;

const HeroSubHeader = styled.h2`
  font-size: clamp(2rem, 2.5vw, 2.5rem);
  line-height: 2.5rem;
  max-width: 70%;
  margin-bottom: 1.5rem;
`;

type HeroSectionProps = {
  imageUrl: string;
  headerText?: string;
  subHeaderText?: string;
  buttonText?: string;
  buttonLink?: string;
};

const HeroSection = ({
  imageUrl,
  headerText,
  subHeaderText,
  buttonText,
  buttonLink,
}: HeroSectionProps) => {
  return (
    <>
      <HeroContainer style={{ backgroundImage: `url(${imageUrl})` }}>
        <HeroTextContainer>
          {headerText && (
            <HeroHeader>
              {headerText.includes('Vea') ? (
                <>
                  Helsehuset <span className='text-red-600'>Vea</span>{' '}
                </>
              ) : (
                headerText
              )}
            </HeroHeader>
          )}

          {subHeaderText && <HeroSubHeader>{subHeaderText}</HeroSubHeader>}

          {buttonText && buttonLink && (
            <div className='w-fit'>
              <ButtonDanger buttonText={buttonText} buttonLink={buttonLink} />
            </div>
          )}
        </HeroTextContainer>
      </HeroContainer>
    </>
  );
};

export default HeroSection;

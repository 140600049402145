import React from 'react';
import styled from 'styled-components';
import Title from '../../components/common/Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem 0 2rem;
  margin: auto;
  max-width: 80rem;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 65% 1fr;
  column-gap: 1%;
  margin-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const TextContainer = styled.div``;

const TextContent = styled.p`
  font-size: 1.3rem;
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
`;

const Coffeespace = () => {
  return (
    <Container>
      <Title text='Kaffekroken'></Title>
      <ContentContainer>
        <TextContainer>
          <TextContent>
            {
              'Helsehuset Vea vektlegger alle aspekter med å ha en god helse, også viktigheten av sosial kontakt og menneskelig interaksjon.'
            }
            {'\n\n'}
            {
              'Vi har derfor Kaffekroken hos oss, men en toppmoderne kaffemaskin hvor man kan kjøpe seg en deilig kopp kaffe og nyte den sammen med andre i vår koselige sofagruppe utenfor treningsrommet.'
            }
          </TextContent>
        </TextContainer>
      </ContentContainer>
    </Container>
  );
};

export default Coffeespace;

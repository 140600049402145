import React from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';

const SuccessContainer = styled.div`
  padding: 2.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  background-color: #d7f9e0; /* Soothing green background color */
`;

const SuccessHeader = styled.h2`
  margin-top: 2rem;
  font-size: 1.6rem;
  padding: 0 3rem;
  text-align: center;
  color: #000; /* Green color for the text */
`;

const SuccessText = styled.p`
  margin-top: 1rem;
  max-width: 90%;
  font-size: 1.2rem;
  text-align: center;
  color: #000; /* Green color for the text */
`;

const Link = styled.a`
  &:hover {
    text-decoration-line: underline;
    text-decoration-color: rgb(224 36 36 / var(--tw-bg-opacity));
  }
`;

const SuccessAlert = () => {
  return (
    <SuccessContainer>
      <FaCheckCircle size={80} color='#008000' />
      <SuccessHeader>
        Takk for din søknad som medlem på Helsehuset{' '}
        <span className='text-red-600'>Vea</span>!
      </SuccessHeader>
      <SuccessText>
        Vi vil ta kontakt med deg når vi har vurdert søknaden.
      </SuccessText>
      <SuccessText>
        Hvis du har spørsmål angående din søknad, send oss en e-post på{' '}
        <Link>post@helsehusetvea.no</Link>.
      </SuccessText>
    </SuccessContainer>
  );
};

export default SuccessAlert;

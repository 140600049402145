import React from 'react'
import styled from 'styled-components';


type SubtitleProps = {
    text: string;
  };
  
  const Text = styled.h3`
    font-size: clamp(1.4rem, 6vw, 1.8rem);
  `;

const Subtitle = ({text}: SubtitleProps) => {
  return (
    <Text>{text}</Text>
  )
}

export default Subtitle
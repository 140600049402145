import React from 'react';
import Title from '../../components/common/Title';
import styled from 'styled-components';
import ButtonDanger from '../../components/common/buttons/ButtonDanger';

type Props = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: auto;
  max-width: 80rem;
`;

const Content = styled.p`
  font-size: clamp(1.2rem, 6vw, 1.4rem);
  white-space: pre-wrap;
`;

const ContentContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 55% 1fr;
  column-gap: 5%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;
const ButtonWrapper = styled.div`
  margin-bottom: 0.7rem;
  width: fit-content;
`;

const Link = styled.a`
  text-decoration-line: underline;
  --tw-border-opacity: 1;
  text-decoration-color: rgb(224 36 36 / var(--tw-bg-opacity));
`;

const Appointment = (props: Props) => {
  return (
    <Container>
      <Title text='Bestill time'></Title>

      <ContentContainer>
        <Content>
          {
            'Timebestilling på nett gjelder utelukkende til privat fysioterapeut (inngår ikke på frikort). Hos vår private fysioterapeut er det kort ventetid og man kan raskt komme til en undersøkelse.'
          }
          {'\n\n'}
          {'Priser hos privat fysioterapeut:\n'}
          {'Førstegangsundersøkelse 60 min: 650,-\n'}
          {'Behandling 30 min: 450,-'}
          {'\n\n'}
          {
            'Fysioterapeutene med driftstilskudd har stor pågang og venteliste i ca 3-6 måneder. Hvis man ønsker stå på venteliste, så kan man enten kontakte oss på tlf '
          }
          <Link href='tel:+4746632222'>466 32 222</Link> {'eller på e-post: '}
          <Link>post@helsehusetvea.no</Link>
          {'.'}
        </Content>
        <ButtonContainer>
          <ButtonWrapper>
            <ButtonDanger
              fontSize={1}
              buttonText='TIME FØRSTEGANGSKONSULTASJON'
              buttonLink='https://timebestilling.aspit.no/#/p3270/services/1/appointment/1/calendar#calendar-title'
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonDanger
              fontSize={1}
              buttonText='TIME FOR BEHANDLING'
              buttonLink='https://timebestilling.aspit.no/#/p3270/services/1/appointment/2/calendar#calendar-title'
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonDanger
              fontSize={1}
              buttonText='TIME FOR SÅLE OG TILPASNING'
              buttonLink='https://timebestilling.aspit.no/#/p3270/services/1/appointment/3/calendar#calendar-title'
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonDanger
              fontSize={1}
              buttonText='TIME FOR TRENINGSVEILEDNING'
              buttonLink='https://timebestilling.aspit.no/#/p3270/services/1/appointment/4/calendar#calendar-title'
            />
          </ButtonWrapper>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default Appointment;

import { Popover} from '@headlessui/react';
import { useRef, useState } from 'react';
import logo from '../../../assets/logo.jpg';
import { NavLink } from 'react-router-dom';
import ButtonDanger from '../buttons/ButtonDanger';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Bars3Icon,
  XMarkIcon,
  UsersIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/solid';

const Header = ({ user }: any) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isGymOpen, setIsGymOpen] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleAboutMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsAboutOpen(true);
    setIsGymOpen(false);
  };

  const handleAboutMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsAboutOpen(false);
    }, 200);
  };

  const menu = [
    { name: 'Hjem', to: '/' },
    {
      name: 'Om oss',
      to: '/ansatte',
      subMenu: [
        {
          name: 'Ansatte',
          to: '/ansatte',
          icon: UsersIcon,
        },
        {
          name: 'Klinikken',
          to: '/klinikken',
          icon: BuildingOfficeIcon,
        },
      ],
      mousseEnter: handleAboutMouseEnter,
      mouseLeave: handleAboutMouseLeave,
    },
    { name: 'Gym', to: '/gym' },
    { name: 'Kaffekroken', to: '/kaffekroken' },
    { name: 'Behandling', to: '/behandling' },
    { name: 'Kontakt oss', to: '/kontakt-oss' },
  ];

  const mobileMenu = [
    { name: 'Hjem', to: '/' },
    { name: 'Ansatte', to: '/ansatte' },
    { name: 'Klinikken', to: '/klinikken' },
    { name: 'Gym', to: '/gym' },
    { name: 'Kaffekroken', to: '/kaffekroken' },
    { name: 'Behandling', to: '/behandling' },
    { name: 'Kontakt oss', to: '/kontakt-oss' },
  ];

  return (
    <>
      <header className='font-poppins h-[100px] bg-white border-red-700 border-b fixed top-0 z-50 w-full'>
        <nav className='mx-auto flex max-w-7xl items-center justify-between p-4'>
          <div className='flex lg:flex-1'>
            <NavLink to='/' onClick={() => setMobileMenuOpen(false)}>
              <span className='sr-only'>Helsehuset Vea</span>
              <img
                className='h-16 w-auto'
                src={logo}
                alt='Logo for Helsehuset Vea'
              />
            </NavLink>
          </div>
          <div className='flex mobileHeader:hidden'>
            <button type='button' className='rounded-md text-gray-700'>
              {!mobileMenuOpen && (
                <>
                  <span className='sr-only'>Åpne meny</span>
                  <Bars3Icon
                    className='h-8 w-8'
                    aria-hidden='true'
                    onClick={() => setMobileMenuOpen(true)}
                  />
                </>
              )}
              {mobileMenuOpen && (
                <>
                  <span className='sr-only'>Lukke meny</span>
                  <XMarkIcon
                    className='h-8 w-8'
                    aria-hidden='true'
                    onClick={() => setMobileMenuOpen(false)}
                  />
                </>
              )}
            </button>
          </div>

          <Popover.Group className='hidden mobileHeader:flex mobileHeader:gap-x-10'>
            {menu.map(page => (
              <div key={page.name}>
                {!page.subMenu && (
                  <NavLink
                    className={({ isActive }) =>
                      'underline-offset-8 hover:underline hover:decoration-red-500 text-[18px] leading-6 text-gray-900 ' +
                      (isActive ? 'underline decoration-red-500' : '')
                    }
                    to={page.to}
                  >
                    {page.name}
                  </NavLink>
                )}
                {page.subMenu && (
                  <Popover className='relative'>
                    <NavLink
                      className={({ isActive }) =>
                        'underline-offset-8 hover:underline hover:decoration-red-500 text-[18px] leading-6 text-gray-900 ' +
                        (isActive ? 'underline decoration-red-500' : '')
                      }
                      to={page.to}
                      onMouseEnter={page.mousseEnter}
                      onMouseLeave={page.mouseLeave}
                    >
                      <div className='flex'>
                        {page.name}
                        <ChevronDownIcon
                          className='h-5 w-5 flex-none text-red-500'
                          aria-hidden='true'
                        />
                      </div>
                    </NavLink>

                    {((isAboutOpen && page.name === 'Om oss') ||
                      (isGymOpen && page.name === 'Gym')) && (
                      <div
                        onMouseEnter={page.mousseEnter}
                        onMouseLeave={page.mouseLeave}
                        className='absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5'
                      >
                        <div className='p-4'>
                          {page.subMenu.map(item => (
                            <NavLink
                              key={item.name}
                              className={({ isActive }) =>
                                'underline-offset-8 hover:underline hover:decoration-red-500 text-[18px] leading-6 text-gray-900 ' +
                                (isActive ? 'underline decoration-red-500' : '')
                              }
                              to={item.to}
                            >
                              <div className='group relative flex items-center gap-x-6 rounded-lg p-4 leading-6 hover:bg-gray-50'>
                                <div className='flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white'>
                                  <item.icon
                                    className='h-6 w-6 text-gray-600 group-hover:text-red-600'
                                    aria-hidden='true'
                                  />
                                </div>
                                <div className='flex-auto'>{item.name}</div>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    )}
                  </Popover>
                )}
              </div>
            ))}
          </Popover.Group>

          <div className='font-poppins hidden lg:flex lg:flex-1 lg:justify-end text-xxl'></div>
          <ButtonDanger
            fontSize={1}
            buttonText='BESTILL TIME'
            buttonLink='/bestill-time'
            onClick={() => setMobileMenuOpen(false)}
          />
        </nav>

        {mobileMenuOpen && (
          <div className='lg:hidden'>
            <div className='mt-20 fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-1 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
              <div className='flow-root text-center'>
                <div className='divide-y divide-gray-500/10'>
                  <div className='space-y-2 py-6'>
                    {mobileMenu.map(page => (
                      <div key={page.name + '-mobile'}>
                        <NavLink
                          className={({ isActive }) =>
                            'underline-offset-8 -mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-gray-50 ' +
                            (isActive ? 'underline decoration-red-500' : '')
                          }
                          to={page.to}
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          {page.name}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                  <div className='max-w-[50%] text-center mx-auto'>
                    <ButtonDanger
                      buttonText={'BLI MEDLEM'}
                      buttonLink={'/bli-medlem'}
                      fontSize={1}
                      onClick={() => setMobileMenuOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type ButtonDangerProps = {
  buttonText: string;
  buttonLink: string;
  fontSize?: number;
  onClick?: any,
};

const ButtonDanger = ({
  buttonText,
  buttonLink,
  fontSize = 1.25,
  onClick
}: ButtonDangerProps) => {
  const Button = styled.div`
    --tw-bg-opacity: 1;
    background-color: rgb(224 36 36 / var(--tw-bg-opacity));
    line-height: 1.75rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.25rem;
    color: #fff;
    font-size: clamp(${fontSize - 0.1}rem, 6vw, ${fontSize}rem);

    &:hover {
      background-color: rgb(200 30 30 / var(--tw-bg-opacity));
    }
  `;

  return (
    <>
      {buttonText && buttonLink && (
        
        <Link to={buttonLink} onClick={onClick}>
        <Button>
          {buttonText}
        </Button>
        </Link>
      )}
    </>
  );
};

export default ButtonDanger;

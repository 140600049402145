import React from 'react';
import styled from 'styled-components';

type TitleProps = {
  text: string;
};

const Text = styled.h1`
  font-size: clamp(2.5rem, 6vw, 3.5rem);
`;

const Title = ({ text }: TitleProps) => {
  return <Text>{text}</Text>;
};

export default Title;

import React from 'react';

type ErrorAlertProps = {
  title: string;
  text: string;
};

const ErrorAlert = ({ title, text }: ErrorAlertProps) => {
  return (
    <div
      className='p-4 mb-4 text-lg text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
      role='alert'
    >
      <span>{title}</span> {text}
    </div>
  );
};

export default ErrorAlert;

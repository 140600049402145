import React from 'react';
import EmployeeCard from '../../components/EmployeeCard';
import kristian from '../../assets/Kristian_Nes.jpg';
import pal from '../../assets/Pal_Ripnes.jpg';
import rut from '../../assets/Rut_Lovik.jpg';
import silje from '../../assets/Silje_Andreassen.jpg';
import solfrid from '../../assets/Solfrid_Dahle.jpg';
import ansatte from '../../assets/Ansatte.jpg';


import HeroSection from '../../components/HeroSection';

export type Employee = {
  name: string;
  imageUrl: string;
  jobTitle: string;
};

const Employees = () => {
  const employees: Employee[] = [
    {
      name: 'Kristian Nes',
      imageUrl: kristian,
      jobTitle: 'Fysioterapeut',
      },
    {
      name: 'Pål Ripnes',
      imageUrl: pal,
      jobTitle: 'Fysioterapeut',
     },
    {
      name: 'Solfrid A. Dahle',
      imageUrl: solfrid,
      jobTitle: 'Fysioterapeut',
      },
    {
      name: 'Silje Kristin Andreassen',
      imageUrl: silje,
      jobTitle: 'Fysioterapeut',
      },
    {
      name: 'Rut Malene Løvik',
      imageUrl: rut,
      jobTitle: 'Fysioterapeut',
      },
  ];

  return (
    <div className='pb-16'>
      <HeroSection imageUrl={ansatte}></HeroSection>
      <div className='max-w-[80rem] mx-auto'>
        <div className='flex flex-wrap justify-normal'>
          {employees.map(employee => (
            <EmployeeCard
              key={employee.name}
              employee={employee}
            ></EmployeeCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Employees;
